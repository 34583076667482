import React, { useState, useEffect, useContext } from 'react'
import AppContext from '../../context/AppContext'
import './styles.css'
// assets
import Back from '../../assets/back.svg'
import Exchange from '../../assets/exchange-small.svg'
// components 
import Loader from '../../helpers/Loader'
import { getBankName, banks } from '../../helpers/Banks';
// api 
import { mobilePaymentWithdrawFunds } from '../../api/Payments'
// helpers 
import { AlertError, AlertWarn } from '../../helpers/SweetAlert'

const UserOtp = ({
    service,
    setStep,
    color
}) => {
    /**
     * context api
     */
    const { user, paymentData, setPaymentData, loading, setLoading, logOut } = useContext(AppContext)

    const handletokenExpired = () => {
        logOut()
        AlertWarn('Tu sesión ha expirado, por favor ingresa para realizar un reintegro.', '¡Ups!')
    }

    const handleContinue = async () => {
        const codes = {
            '00': '',
            '01': 'Saldo insuficiente para llevar a cabo la operación',
            '02': 'El monto de la operación es mayor al limite permitido',
            '03': 'Ya dispone de un reintegro en progreso',
            '05': 'Ocurrió un error, por favor intentalo mas tarde',
            '50': 'Ocurrió un error, por favor intentalo mas tarde',
            '411': 'No puedes realizar un reintegro teniendo un viaje en curso. Intenta nuevamente luego de finalizar el viaje.',
        }
        const code = [
            '01',
            '02',
            '03',
            '05',
            '50',
        ]
        setLoading(true)
        try {
            const data = {
                bankCode: paymentData.bank,
                phone: user.formattedPhone,
                fiscalRegistry: paymentData.doc,
                transactionPhone: `+58${paymentData.phone}`,
                amount: Number(paymentData.amount.usd),
                amountBSD: Number(paymentData.amount.ves),
                USDRate: Number(paymentData.rate),
                userId: user._id,
                vertical: service.name === "Yummy Delivery" ? 'delivery' : 'rides'
            }  
            const res = await mobilePaymentWithdrawFunds(data, user.token, handletokenExpired)
            // if(res.code === '01' || res.code === '02' || res.code === '03' || res.code === '05' || res.code === '50') {
            if(code.includes(res.code)) {
                AlertError(codes[res.code])
                logOut()
                setLoading(false)
                return
            }
            if(res.code === '411'){
                AlertWarn(codes[res.code], '¡Espera!')
                setLoading(false)
                setStep(3)
                return
            }
            setPaymentData(prevState => {
                setLoading(false)
                return {...prevState, ...res };
              })
            setStep(5)
        } catch(e){
            setLoading(false)
            console.log("🚀 ~ file: UserPaymentData.jsx ~ line 34 ~ handleContinue ~ e", e)
        }
    }

    const handleBackButton = () => {
        // logOut()
        // setLoading(false)
        setStep(3)
    }

    return (
        <div className='content-container'>
            <div className='relative' onClick={handleBackButton}>
                <img src={Back} className='back' />
                <p className='heading-small neutral-60 text-center'>{user.first_name} {user.last_name}</p>
            </div>
            <div className='user-payment-data-container'>
                <p className='text-size-small semi-bold neutral-50'>Reintegro por</p>
                <p className='heading-large neutral-60'>{paymentData.amount && Number(paymentData.amount.usd).toFixed(2)} USD</p>
                <div className='flex mb-l'>
                    <img src={Exchange} className='mr-s' alt="exchange icon" />
                    <p className='text-size-small bold neutral-60'>Bs. {paymentData.amount && Number(paymentData.amount.ves).toFixed(2)}</p>
                </div>
                

                <p className='text-size-small semi-bold neutral-50 mb-s'>Datos del Pago Móvil</p>
                <p className='text-size-xsmall mid-gray'>Cédula: <span className='text-size-small neutral-60 bold'>{paymentData.doc}</span></p>
                <p className='text-size-xsmall mid-gray'>Teléfono: <span className='text-size-small neutral-60 bold'>{paymentData.phone}</span></p>
                <p className='text-size-xsmall mid-gray'>Banco: <span className='text-size-small neutral-60 bold'>{getBankName(paymentData.bank, banks)}</span></p>

            </div>
            {
                !loading ?
                    <form className='button-container' onSubmit={handleContinue}>
                        <button className='primary-button' style={{ backgroundColor: color }
                        }>RETIRAR</button>
                    </form>
                    :
                    <Loader service={service.name} />
            }
        </div>
    )
}

export default UserOtp
