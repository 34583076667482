import axios from "axios"

export const getDolarRate = async () => {
    try {
        const path = 'transactions/rate'
        const res = await fetch(`${process.env.REACT_APP_API}/${path}/`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "x-api-key": `${process.env.REACT_APP_API_KEY_PAYMENTS}`
            }
        })
        const response = await res.json()
        return response
    }catch(e){
        console.log(e)
    }
}

export const getBanksList = async () => {
    try {
        const path = 'withdrawal/banks'
        const res = await fetch(`${process.env.REACT_APP_API}/${path}/`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "x-api-key": `${process.env.REACT_APP_API_KEY_PAYMENTS}`
            }
        })
        const response = await res.json()
        return response
    }catch(e){
        console.log(e)
    }
}

export const getLimit = async () => {
    try {
        const path = 'withdrawal/limit'
        const res = await fetch(`${process.env.REACT_APP_API}/${path}/`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "x-api-key": `${process.env.REACT_APP_API_KEY_PAYMENTS}`
            }
        })
        const response = await res.json()
        return response
    }catch(e){
        console.log(e)
    }
}

export const mobilePaymentWithdrawFunds = async (data, token, handletokenExpired) => {
    try {
        const path = 'withdrawal/withdraw'
        const res = await fetch(`${process.env.REACT_APP_API}/${path}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        if(res.status === 401){
            handletokenExpired()
            return
        }
        const response = await res.json()
        return response
    }catch(e){
        console.log("🚀 ~ file: UserApi.js ~ line 51 ~ getUserData ~ e", e.respose)
        return { code: '05' }
    }
}
