import React, { useState, useEffect } from 'react';
import AppContext from './AppContext';
import { getDolarRate, getBanksList } from '../api/Payments';
import { clearStorage } from '../helpers/Helpers';
import { AlertError } from '../helpers/SweetAlert';
// api
import { getRidesUserData, getDeliveryUserData } from '../api/UserApi';
// helpers
import { splitFullName } from '../helpers/Helpers';
import { services as serviceTypes } from '../helpers/Services';

const AppState = ({ children }) => {
  /**
   * states and setters for input events and styles
   */
  const [user, setUser] = useState(false);
  const [userData, setUserData] = useState(false);

  const [limit, setLimit] = useState(0);
  const [rate, setRate] = useState(0);
  const [banks, setBanks] = useState([]);
  const [loggedLoading, setLoggedLoading] = useState(false);

  const [service, setService] = useState(false);
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [paymentData, setPaymentData] = useState({});

  // query string getting from url
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  /**
   * funcs that call the paymethods func
   */
  useEffect(() => {
    promiseData();
    // getUser();
    const serviceParams = urlParams.get('service');
    const phoneParams = urlParams.get('phoneNumber');
    if (serviceParams && phoneParams) {
      serviceParams.toLowerCase() === 'rides' && setService(serviceTypes[1]);
      serviceParams.toLowerCase() === 'delivery' && setService(serviceTypes[0]);
    }
  }, []);
  useEffect(() => {
    service && setStep(1);
  }, [service]);

  const promiseData = async () => {
    try {
      setLoggedLoading(true);
      await Promise.all([handleDolarRate(), handleBanks()]);
      setLoggedLoading(false);
    } catch (e) {
      logOut();
      console.log(e);
    }
  };

  /**
   * func that fetch dolar rate
   */
  const handleDolarRate = async () => {
    const data = await getDolarRate();
    // console.log("🚀 ~ file: AppState.jsx ~ line 53 ~ handleDolarRate ~ data", data)
    if (data) {
      if (!data.success) {
        AlertError('Ocurrió un error, por favor inténtalo mas tarde');
        logOut();
        return;
      }
      data.success && setRate(data.rate);
    }
  };
  /**
   * func that fetch dolar rate
   */
  const handleBanks = async () => {
    const data = await getBanksList();
    // console.log("🚀 ~ file: AppState.jsx ~ line 67 ~ handleBanks ~ data", data)
    if (data) {
      if (!data.success) {
        AlertError('Ocurrió un error, por favor inténtalo mas tarde');
        logOut();
        return;
      }
      data.success && setBanks(data.banks);
    }
  };

  const logOut = () => {
    setStep(0);
    clearStorage();
  };

  useEffect(() => {
    const localUserData = window.localStorage.getItem('userData');
    const parsedData = JSON.parse(localUserData);
    window.localStorage.setItem(
      'userData',
      JSON.stringify({ ...parsedData, user: { ...user } })
    );
  }, [user]);

  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        rate,
        setRate,
        limit,
        service,
        setService,
        userData,
        setUserData,
        step,
        setStep,
        loading,
        setLoading,
        loggedLoading,
        setLoggedLoading,
        paymentData,
        setPaymentData,
        banks,
        logOut,
      }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppState;
