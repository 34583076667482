import React, { useState, useEffect, useContext } from 'react';
import './styles.css';
import Back from '../../assets/back.svg';
import AppContext from '../../context/AppContext';
import Loader from '../../helpers/Loader';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ErrorSvg from '../../assets/warn.svg';
import CheckSvg from '../../assets/check.svg';
import { splitFullName } from '../../helpers/Helpers';
import { getRidesUserData, getDeliveryUserData } from '../../api/UserApi';

const UserData = ({ setUser, service, setStep, color }) => {
  // query string getting from url
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const phoneParams = urlParams.get('phoneNumber');

  /**
   * context api
   */
  const { loading, setLoading } = useContext(AppContext);
  /**
   * React states
   */
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState(true);
  const [inputStyles, setInputStyles] = useState({});
  const [check, setCheck] = useState(false);
  const [countrySelected, setCountrySelected] = useState('');
  const [data, setData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [noCoincidence, setNoCoincidence] = useState(false);
  const [loadingParams, setLoadingParams] = useState(false);

  // url params function
  useEffect(() => {
    if (phoneParams) {
      setLoadingParams(true);
      getUser(phoneParams, service.name);
    }
  }, []);
  /**
   * func that get the user by service (rides or delivery)
   * @param {*} e
   */
  const getUserData = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (!phoneError) {
        getUser(phone, service.name);
      }
    } catch (e) {
      setLoading(false);
      console.log('🚀 ~ file: UserData.jsx ~ line 77 ~ getUserData ~ e', e);
    }
  };

  const getUser = async (phone) => {
    reserStates();
    let data;
    if (service.name === 'Yummy Rides') {
      data = await getRidesUserData(phone);
      if (data.success) {
        const lastName = data.user.last_name.charAt(0);
        setData({
          ...data.user,
          formattedPhone: `+${phone}`,
        });
        setUserData({
          first_name: data.user.first_name,
          initial_lastname: `${lastName}.`,
        });
      } else {
        setNoCoincidence(true);
      }
      setLoading(false);
      setLoadingParams(false);
    } else {
      data = await getDeliveryUserData(phone);
      if (data) {
        const splitName = splitFullName(data.name);
        const lastName = splitName[1].charAt(0);
        setData({
          ...data,
          first_name: splitName[0],
          last_name: splitName[1],
          formattedPhone: `+${phone}`,
          _id: data.client_id,
        });
        setUserData({
          first_name: splitName[0],
          initial_lastname: `${lastName}.`,
        });
      } else {
        setNoCoincidence(true);
      }
      setLoading(false);
      setLoadingParams(false);
    }
  };

  /**
   * setting the user data in context and local storage
   * @param {*} e
   */
  const setUserInContext = (e) => {
    e.preventDefault();
    let lastName = '';
    if (service.name === 'Yummy Rides') {
      lastName = data.last_name.charAt(0);
    } else {
      const splitname = splitFullName(data.name);
      lastName = splitname[1].charAt(0);
    }
    setUser({ ...data, initial_lastname: lastName });
    const localUserData = window.localStorage.getItem('userData');
    const parsedData = JSON.parse(localUserData);
    window.localStorage.setItem(
      'userData',
      JSON.stringify({
        ...parsedData,
        user: { ...data, initial_lastname: lastName },
      })
    );
    setStep(2);
  };
  /**
   * func that reset the states
   */
  const reserStates = () => {
    setNoCoincidence(false);
    setCheck(false);
    setPhoneError(false);
  };
  /**
   * effect to set '58' on phone state
   */
  useEffect(() => {
    setPhone('58');
  }, []);

  /**
   * number and country setter
   * @param {} value phone number
   * @param {object} country
   */
  const onChangePhoneNumber = (value, country) => {
    if (countrySelected.name !== country.name) {
      setPhone(country.dialCode);
      setCountrySelected(country);
      setPhoneError(true);
      setNoCoincidence(false);
    } else {
      setNoCoincidence(false);
      setPhone(value);
      hasErrorPhoneNumber();
    }
  };
  /**
   * phone number error handler, only available on venezuela code
   */
  const hasErrorPhoneNumber = () => {
    if (phone.startsWith('58')) {
      if (!phone.match(/^([0-9]){11}$/)) {
        setPhoneError(true);
        setCheck(false);
      } else {
        setPhoneError(false);
        setCheck(true);
      }
    } else {
      setPhoneError(false);
      setCheck(false);
    }
  };
  /**
   * states and setters for input events and styles
   */
  const onFocusInput = (e) => {
    if (e.type === 'focus') {
      setInputStyles({
        border:
          service.name === 'Yummy Rides'
            ? '2px solid var(--purple-40)'
            : '2px solid var(--green)',
      });
    } else {
      setInputStyles({});
    }
  };
  /**
   * render the first step where the input is show
   * @returns JSX
   */
  const renderStepOne = () => {
    return (
      <>
        <p className="modal-info">
          Introduce el número de teléfono de tu usuario en {service.name}
        </p>
        <div className="user-input-container" style={inputStyles}>
          <p className="user-input-label">Número de teléfono</p>
          <PhoneInput
            country={'ve'}
            value={phone}
            countryCodeEditable={false}
            enableSearch={true}
            onFocus={onFocusInput}
            onBlur={onFocusInput}
            onChange={onChangePhoneNumber}
          />
          {phoneError && phone.startsWith('58') && (
            <p className="verify error-phone">
              <img src={ErrorSvg} />
              El número debe tener 12 dígitos
            </p>
          )}
          {check && phone.startsWith('58') && (
            <p className="verify check-phone">
              <img src={CheckSvg} style={{ marginRight: 5 }} />
              Formato correcto
            </p>
          )}
        </div>
        {noCoincidence && <p className="no-results">búsqueda sin resultados</p>}
        {!loading ? (
          <form className="button-container" onSubmit={getUserData}>
            <button
              className="primary-button"
              disabled={phoneError}
              style={
                phoneError
                  ? { backgroundColor: 'var(--neutral-50)' }
                  : { backgroundColor: color }
              }>
              ACEPTAR
            </button>
          </form>
        ) : (
          <Loader service={service.name} />
        )}
      </>
    );
  };

  /**
   * render the two step where the user info is show
   * @returns JSX
   */
  const renderStepTwo = () => {
    return (
      <>
        <p className="modal-info">
          ¿Este es el nombre de tu usuario en {service.name}?
        </p>
        <p className="user-input-label text-center m-top">Nombre</p>
        <p className="user-name">
          {userData.first_name} {userData.initial_lastname}
        </p>
        <form className="button-container" onSubmit={setUserInContext}>
          <button className="primary-button" style={{ backgroundColor: color }}>
            Sí
          </button>
          <p
            className="text-center cancel-button"
            style={{ color: color }}
            onClick={() => {
              setUserData(null);
              setPhoneError(true);
              setPhone(countrySelected.dialCode);
            }}>
            NO
          </p>
        </form>
      </>
    );
  };

  /**
   * change the step and remove de user info from local storage
   */
  const handleBackButton = () => {
    if (phoneParams) {
        let url= document.location.href;
        window.history.pushState({}, "", url.split("?")[0]);
    }
    window.localStorage.removeItem('userData');
    setLoading(false);
    setStep(0);
  };

  return (
    <div className="content-container">
      {loadingParams ? (
        <div className="loader-container" style={{ margin: 'auto' }}>
          <Loader service={service.name} />
        </div>
      ) : (
        <>
          <div className="relative" onClick={() => handleBackButton()}>
            <img src={Back} className="back" />
            <p className="modal-title">Datos del usuario</p>
          </div>
          {!userData ? renderStepOne() : renderStepTwo()}
        </>
      )}
    </div>
  );
};

export default UserData;
