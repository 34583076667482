import React, { useContext, useEffect, useState } from  'react'
import AppContext from '../../context/AppContext'
// libs 
import { useTransition, animated } from 'react-spring'
// components 
import Loader from '../../helpers/Loader'
// views
import ServicesType from '../services-type/ServicesType'
import UserData from '../user-data/UserData'
import UserOtp from '../user-otp/UserOtp'
import PagoMovil from '../pay-methods/PagoMovil.jsx'
import UserPaymentData from '../user-payment-data/UserPaymentData'
import Success from '../success/Success'
// helpers 
import { getStorage } from '../../helpers/Helpers'
import Logo from '../../assets/logo.svg'


const Modal = () => {

    const {

        user, // user information
        setUser, // user data setter
        service,  // service data
        setService, // service setter
        step, // step
        setStep, // step setter
        loggedLoading,
    } = useContext(AppContext)

    /**
     * React spring transitions
     * @param {int} step 
     * @returns React component
     */
    const transition = useTransition(step, {
        from: { x: -50, opacity: 0, height: '100%', width: '100%' },
        enter: { x: 0, opacity: 1, height: '100%', width: '100%' },
        // leave: { x: 50, opacity: 0 }
    })
    
    // effect to call checcPayment and get user functions
    useEffect(()=>{
        getUserLogged()
    },[])

    /**
     * func to get user info when they come from apps, it take data from url params y send to pay methods view
     * user isnt allowed to select diferent service or search another user information
     */
    const getUserLogged = async () => {
        const data = getStorage('userData')
        if(data){
            setService(data.service)
            if(data.user && data.user.token){
                setUser(data.user)
                setStep(3)
            }
        }
    }
    /**
     * effect for set the color, it depends of service selected
     */
    const [ color, setColor ] = useState('')
    useEffect(()=>{
        const color = service ? service.bgColor : null
        setColor(color)
    },[service])
    
// borrar comentario  1
    return (
        <div className='modal shadow'>
            <div style={{ margin: 'auto', textAlign: 'center' }}>
                <img style={{ width: '70px', marginBottom: 20 }} src={Logo} alt="yummy logo" />
                <p>Servicio no disponible 😔</p>
                <p>¡Estaremos de vuelta pronto!</p>
            </div>
            {/* {
                loggedLoading
                ?
                <>
                    <div className='loader-container' style={{ margin: 'auto' }}>
                        <Loader service={service.name} />
                    </div>
                </>
                :
                <>
                    {   
                        transition((style, item)=>
                            item === 0 && 
                            <animated.div style={style}>
                                <ServicesType setService={setService} setStep={setStep} />
                            </animated.div> 
                        )
                    }
                    {   
                        transition((style, item)=>
                            item === 1 && 
                            <animated.div style={style}>
                                <UserData setUser={setUser} user={user} service={service} setStep={setStep} color={color} />  
                            </animated.div> 
                        )
                    }
                    {   
                        transition((style, item)=>
                            item === 2 && 
                            <animated.div style={style}>
                                <UserOtp setUser={setUser} user={user} service={service} setStep={setStep} color={color} />  
                            </animated.div> 
                        )
                    }
                    {   
                        transition((style, item)=>
                            item === 3 && 
                            <animated.div style={style}>
                                <PagoMovil setUser={setUser} user={user} service={service} setStep={setStep} color={color} />  
                            </animated.div> 
                        )
                    }
                    {   
                        transition((style, item)=>
                            item === 4 && 
                            <animated.div style={style}>
                                <UserPaymentData service={service} setStep={setStep} color={color} />  
                            </animated.div> 
                        )
                    }
                    {   
                        transition((style, item)=>
                            item === 5 && 
                            <animated.div style={style}>
                                <Success service={service} setStep={setStep} color={color} />  
                            </animated.div> 
                        )
                    }
                </>
            } */}
        </div>
    )
}

export default Modal