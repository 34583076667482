import DeliverySvg from '../assets/delivery.svg'
import RidesSvg from '../assets/rides.svg'

export const services = [
    {
        name: 'Yummy Delivery',
        img: DeliverySvg,
        bgColor: 'var(--green)'
    },
    {
        name: 'Yummy Rides',
        img: RidesSvg,
        bgColor: 'var(--purple-60)'
    },
]
